const Config = {
	// SERVER_HOME: "https://xf.stgame.cn/xf3/client/v1/", //Local online
	// SERVER_HOME: "http://127.0.0.1:10805/client/v1/",//Local
	// SERVER_HOME: "http://production-iq.nikopartners.com/api/client/v1/", //Production domain
	// SERVER_HOME: "http://47.251.36.41:10805/client/v1/", //new production domain
	// SERVER_HOME: "http://47.251.36.41:80/client/v1/", //new development domain
	// SERVER_MAIL_HOME: "http://production-iq.nikopartners.com/client/v1/sdkmail/", //Production Email domain

	// Production env
	// SERVER_HOME: "https://api-iq.nikopartners.com/production/client/v1/", //Test Nginx Production
	// SERVER_MAIL_HOME:
	// 	"https://api-iq.nikopartners.com/production/client/v1/sdkmail/", //Nginx Production Email domain

	// UAT env
	SERVER_HOME: "https://api-iq.nikopartners.com/uat/client/v1/", //Test Nginx uat/uat
	SERVER_MAIL_HOME: "https://api-iq.nikopartners.com/uat/client/v1/sdkmail/", //Nginx uat/uat Email domain

	// Development env
	// SERVER_HOME: "https://api-iq.nikopartners.com/development/client/v1/", //Test Nginx development
	// SERVER_MAIL_HOME:
	// 	"https://api-iq.nikopartners.com/development/client/v1/sdkmail/", //Nginx development Email domain

	// Local env
	// SERVER_HOME: "http://localhost:10806/client/v1/", //Local
	// SERVER_MAIL_HOME: "http://localhost:10806/client/v1/sdkmail/", //Local env Email domain
	// UPLOAD_SERVER_HOME: "",
};
module.exports = Config;
